<template>
    <div class="chatgpt">
        <el-card>
            <nav class="out">
                <nav class="input">
                    <el-input
                        v-model="search.keyword"
                        size="medium"
                        placeholder="输入搜索问题关键词"
                    />
                </nav>
                <nav class="input" style="width: auto">
                    <el-select
                        v-model="search.recommend"
                        placeholder="精选"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="精选" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: auto">
                    <el-select
                        v-model="search.hide"
                        placeholder="状态"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="上架" :value="0" />
                        <el-option label="下架" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 350px">
                    <el-date-picker
                        v-model="search.createDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        size="medium"
                    />
                </nav>
                <nav class="input">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{
                        '搜索'
                    }}</el-button>
                    <el-button
                        type="primary"
                        :loading="loading"
                        size="small"
                        @click="derive"
                    >
                        导出
                    </el-button>
                </nav>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
            >
                <el-table-column label="Id" prop="id"></el-table-column>
                <el-table-column label="头像">
                    <template #default="{ row }">
                        <p-image :src="row.avatar"></p-image>
                    </template>
                </el-table-column>
                <el-table-column label="名字" prop="userName"></el-table-column>
                <el-table-column label="问题" prop="content"></el-table-column>
                <el-table-column label="回答预览">
                    <template slot-scope="{ row }">
                        <div class="clamp" @click="toView(row.answer.content)">
                            {{ row.answer.content }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="类型">
                    <template #default="{ row }">
                        <Remark url="/admin/Chatgpt/remark" :row="row"></Remark>
                    </template>
                </el-table-column>
                <el-table-column label="点赞量" prop="likeNum">
                </el-table-column>
                <el-table-column label="收藏量" prop="collectNum">
                </el-table-column>
                <el-table-column label="提间时间" prop="createTime">
                </el-table-column>
                <el-table-column label="回答时间" prop="answer.createTime">
                </el-table-column>
                <el-table-column label="状态">
                    <template #default="{ row }">
                        <el-tag
                            size="small"
                            :type="row.hide == 0 ? 'warning' : 'info'"
                            >{{ row.hide == 0 ? '已上架' : '已下架' }}</el-tag
                        >
                        <el-tag size="small" v-if="row.recommend == 1">
                            精选
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="{ row }">
                        <el-button
                            type="text"
                            icon="el-icon-chat-line-round"
                            size="mini"
                            @click="edit(row)"
                            >{{
                                row.recommend == 1 ? '取消精选' : '精选'
                            }}</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="del(row)"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-view"
                            size="mini"
                            style="color: tan"
                            @click="toViewQ(row)"
                        >
                            查看问答明细
                        </el-button>
                        <el-button
                            type="text"
                            icon="el-icon-position"
                            size="mini"
                            style="color: skyblue"
                            @click="upAndDown(row)"
                        >
                            {{ row.hide == 0 ? '下架' : '上架' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div-pagination
                :total="total"
                :search="search"
                @currentChange="currentChange"
            ></div-pagination>
        </el-card>
    </div>
</template>

<script>
// import loadingButtonVue from './loading-button.vue'
import list from '/src/mixin/list.js'
export default {
    mixins: [list],

    data() {
        return {
            list: [],
            search: {
                page: 1,
                pageSize: 10,
                hide: '',
                recommend: '',
                createDate: [],
            },
            total: 0,
            loading: false,
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        async getlist() {
            let { data: res } = await this.$http.get('/admin/Chatgpt/getList', {
                params: {
                    ...this.search,
                    createDate: this.search.createDate.join('~'),
                },
            })
            this.list = res.data.list
            this.total = res.data.totalCount || 0
        },
        async derive() {
            this.loading = true
            try {
                let { data: res } = await this.$http.post(
                    '/admin/Chatgpt/exportExcel',
                    {
                        ...this.search,
                        createDate: this.search.createDate.join('~'),
                    }
                )
                if (res.errorCode == 200) {
                    this.$message.success(res.message)
                    location.href = res.data.url
                }
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        toView(val) {
            var h = this.$createElement
            this.$msgbox({
                title: '内容',
                message: h('div', { class: 'pre-wrap' }, val),
            })
        },
        async upAndDown(row) {
            let { data: res } = await this.$http.post('/admin/Chatgpt/hide', {
                id: row.id,
                hide: row.hide == 0 ? 1 : 0,
            })
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.getlist()
            }
        },
        async toViewQ(row) {
            this.$alert(
                `<img style="width:100%" src="${
                    this.$store.getters.imgCol + row.sunCode
                }"/>`,
                '二维码预览',
                {
                    dangerouslyUseHTMLString: true,
                }
            )
        },
        async del(row) {
            let { id } = row
            await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            this.$http
                .post('/admin/Chatgpt/del', {
                    id,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                        if (this.total % 10 == 1) {
                            this.search.page--
                        }
                        this.getlist()
                    }
                })
        },
        async edit(row) {
            let { data: res } = await this.$http.post(
                '/admin/Chatgpt/recommend',
                {
                    id: row.id,
                    recommend: row.recommend == 1 ? 0 : 1,
                }
            )
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.getlist()
            }
        },
        subimt() {
            this.search.page = 1
            this.getlist()
        },
        currentChange(e) {
            this.search.page = e
            this.getlist()
        },
    },
}
</script>

<style lang="less" scoped>
.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    cursor: pointer;
}
.pre-wrap {
    white-space: pre-wrap;
}
/deep/.el-tag {
    margin: 5px;
}
</style>